import { Box, IconButton } from "@mui/material";

import Banner from '../Assets/BannerInicial.webp'
import { CalendarMonth } from "@mui/icons-material";
import Swal from "sweetalert2";
import Titulo from '../Assets/Titulo_festival.svg'

const TopBar = () => {

    const DownloadAdvice = () => {
        Swal.fire({
            title:"¿Desea continuar con la operación?",
            text:"Se descargará un archivo PDF",
            icon:"question",
            showCancelButton: true
        })
    }

    return (
        <>
        {/*Dispositvos móviles y tablets*/}
        <Box sx={{display:{xs:'block', md:'none'}}}>
            <Box component={"img"} src={Banner} alt="Banner" sx={{height:"100%", width:"100%"}}/>
            <Box sx={{position:"absolute", top:3, right:"5%", display:"flex", flexDirection:"column",
                alignItems:"center"
            }}>
                <IconButton sx={{color:"white", border:"2px solid", width:35}}
                size="small" onClick={DownloadAdvice}>
                    <CalendarMonth sx={{color:"white"}} fontSize="small"/>
                </IconButton>
                <label style={{color:"white", fontWeight:"bold", fontSize:10}}>Descargar</label>
                <label style={{color:"white", fontWeight:"bold", fontSize:10}}>Programa</label>
            </Box>
        </Box>

        {/*Computadoras y monitores*/}
        <Box sx={{display:{xs:'none', md:'block'}}}>
            <Box sx={{backgroundImage:"linear-gradient(to right, #C54C5B, #C3181E, #B7CCC6, #36413B, #769A97)", 
                height:"100px", display:"flex", alignItems:"center"}}>
                <Box component={"img"} src={Titulo} sx={{width:"100%", height:"80px"}}/>
            </Box>
        </Box>
        </>
    );
}

export default TopBar;