import './App.css';
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import { rutas } from './routes';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<Navigate to={"/mapa"} replace/>} />

        {rutas.map(
            (ruta, indice) => {
              return (
                <Route 
                  path={ruta.ruta} 
                  key={indice}
                  element={ruta.component}
                  />
              )})}
      </Routes>
    </HashRouter>
  );
}

export default App;
