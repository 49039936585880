import { Box, InputLabel } from "@mui/material";

import LogoTL from '../Assets/TrafficLight_Logo.png'
import LightExp from '../Assets/Ligh_Experiences_TL.png'

import AmorPuebla from '../Assets/PENSAR EN GRANDE@150x.png'
import GobPuebla from '../Assets/logoPuebla.png'
import GobNuevoPuebla from '../Assets/logoNuevoPuebla.png'
import SectConvParq from '../Assets/CONVENCIONES Y PARQUES@150x.png'
import { useEffect, useState } from "react";

const Footer = () => {

    const [currentDateTime, setCurrentDateTime] = useState("");

    // Función para actualizar la fecha actual
    const updateDate = () => {
        const now = new Date();
        const formattedDate = now.toISOString().slice(0, 10); // YYYY-MM-DD
        setCurrentDateTime(formattedDate);
    };

    // Configura el temporizador para actualizar a medianoche
    const setupMidnightUpdater = () => {
        const now = new Date();
        // Calcula cuánto tiempo falta para la próxima medianoche
        const timeUntilMidnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1, // Próximo día
        0, 0, 0, 0 // 12:00 AM
        ) - now;

        // Actualiza la fecha en la próxima medianoche
        setTimeout(() => {
        updateDate(); // Actualiza la fecha
        setupMidnightUpdater(); // Configura el siguiente ciclo de 24 horas
        }, timeUntilMidnight);
    };

    useEffect(() => {
        updateDate(); // Actualiza inmediatamente al montar el componente
        setupMidnightUpdater(); // Configura el temporizador para medianoche
    }, []);

    return (
        <Box sx={{display:"flex", backgroundColor:"#f7f9f9", p:1, height:"auto", maxWidth:"100%", 
            flexDirection:"column"
        }}>
            {/*
            <Box sx={{display:"flex", gap:3, alignItems:"center"}}>
                <InputLabel sx={{fontSize:{xs:"10px", md:"20px"}, fontWeight:"bold", whiteSpace:"normal",
                mr:{xs:"-12%", md:"-12px"}}}>
                Diseñado por:</InputLabel>
                <Box component={"img"} src={LogoTL} alt="LogoAmorPuebla" 
                sx={{width:{xs:'40%', md:'15%'}}}/>
            </Box>
            */}
            {/*
            <Box component={"img"} src={LightExp} alt="LightExp" 
            sx={{width:{xs:'15%', md:'15%'}}}/>
            */}
            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"space-between"
            }}>
                <Box component={"img"} src={SectConvParq} alt="ParqJar" 
                sx={{width:{xs:'19%', md:'10%'}}}/> 
                <Box component={"img"} src={currentDateTime >= "2024-12-15" ? GobNuevoPuebla : GobPuebla} 
                alt="GobPuebla" 
                sx={{width:{xs:currentDateTime >= "2024-12-15" ? '20%' :'30%', md:'15%'}}}/> 
                <Box component={"img"} src={AmorPuebla} alt="LogoAmorPuebla" 
                sx={{width:{xs:'30%', md:'15%'}}}/>
            </div>
            {currentDateTime >= "2024-12-15" &&
                <div style={{display:"flex", justifyContent:"space-around", alignItems:"center",
                }}>
                    <Box component={"img"} src={LightExp} alt="LightExp" 
                    sx={{width:{xs:'15%', md:'15%'}}}/>
                    <Box component={"img"} src={LogoTL} alt="TrafficLogo" 
                    sx={{width:{xs:'20%', md:'15%'}}}/>
                </div>
            }
        </Box>
    );
}

export default Footer;